package fi.bullpen.kmpapp.screens.turnkeyInitEmailRecovery

import fi.bullpen.kmpapp.service.turnkeyIframeStamper.IframeStamper

data class TurnkeyInitEmailRecoveryScreenState(
    val recoveryState: RecoveryState = RecoveryState.InputEmail,
    val iframeStamper: IframeStamper? = null,
    val defaultPasskeyName: String? = null
)

sealed interface RecoveryState {
    sealed interface AwaitingAction : RecoveryState
    data object InputEmail : AwaitingAction
    data object InputRecoveryCode : AwaitingAction
    data object InProgress : RecoveryState
    data object Success : RecoveryState
    data class Error(val message: String) : AwaitingAction
}
