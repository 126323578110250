package fi.bullpen.kmpapp.screens.turnkeyManagement

import cafe.adriel.voyager.core.model.StateScreenModel
import cafe.adriel.voyager.core.model.screenModelScope
import co.touchlab.kermit.Logger
import dev.whyoleg.cryptography.CryptographyProvider
import dev.whyoleg.cryptography.algorithms.digest.SHA256
import fi.bullpen.kmpapp.data.bullpen.BullpenApi
import fi.bullpen.kmpapp.data.bullpen.UserInfoRepository
import fi.bullpen.kmpapp.data.turnkey.TurnkeyApi
import fi.bullpen.kmpapp.data.turnkey.TurnkeyApiKeyRepository
import fi.bullpen.kmpapp.data.turnkey.TurnkeyAuthApiKeyStamper
import fi.bullpen.kmpapp.data.turnkey.dto.*
import fi.bullpen.kmpapp.data.turnkey.walletManagementSessionApiKeyName
import fi.bullpen.kmpapp.screens.generic.UserActionState
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock

class TurnkeyManagementScreenModel(
    val bullpenApi: BullpenApi,
    val turnkeyApi: TurnkeyApi,
    private val userInfoRepository: UserInfoRepository,
    turnkeyApiKeyRepository: TurnkeyApiKeyRepository
) : StateScreenModel<TurnkeyManagementScreenState>(TurnkeyManagementScreenState()) {

    val apiKeyStamper = TurnkeyAuthApiKeyStamper(turnkeyApiKeyRepository)

    fun onInit() {
        refreshPublicBullpenApiKeys()
        screenModelScope.launch {
            refreshTurnKeyUser()?.join()
            removeOldSessionApiKeys()
        }
    }

    private fun refreshPublicBullpenApiKeys() = screenModelScope.launch {
        try {
            val apiKeys = bullpenApi.getPublicBullpenTurnkeyApiKeys()
            mutableState.update { it.copy(bullpenPublicApiKeys = apiKeys) }
        } catch (t: Throwable) {
            Logger.e(t) { "Error in refreshPublicBullpenApiKeys" }
        }
    }


    /***
     * TODO: If the user's session api key is expired, this will throw an error and the user should be logged out.
     */
    private fun refreshTurnKeyUser(): Job? {
        val userTurnkeyInfo = userInfoRepository.get()?.usersTurnkeyInfo
        if (userTurnkeyInfo == null) {
            Logger.e { "Error in refreshTurnKeyUser: userTurnkeyInfo is null" }
            mutableState.update {
                it.copy(modifyApiKeysState = UserActionState.Error("Could not retrieve user info"))
            }
            return null
        }
        return screenModelScope.launch {
            val myTurnkeyUser = try {
                turnkeyApi.getUser(apiKeyStamper, GetUserQuery(userTurnkeyInfo.organizationId, userTurnkeyInfo.userId))
            } catch (t: Throwable) {
                Logger.e(t) { "Error in refreshTurnKeyUser" }
                mutableState.update {
                    it.copy(modifyApiKeysState = UserActionState.Error("Could not retrieve user info: ${t.message}"))
                }
                null
            }
            mutableState.update {
                it.copy(
                    user = myTurnkeyUser?.user, organizationId = userTurnkeyInfo.organizationId
                )
            }
        }
    }

    fun deleteAuthenticator(passkey: Authenticator) {
        if (state.value.modifyAuthenticatorsState !is UserActionState.AwaitingAction) return
        if (state.value.user?.userEmail == null) {
            mutableState.update {
                it.copy(modifyAuthenticatorsState = UserActionState.Error("Email must be set to delete authenticators."))
            }
            return
        }

        mutableState.update {
            it.copy(modifyAuthenticatorsState = UserActionState.InProgress)
        }
        screenModelScope.launch {
            try {
                val turnkeyUser = state.value.user!!
                val organizationId = state.value.organizationId!!
                turnkeyApi.deleteAuthenticators(
                    apiKeyStamper, organizationId, DeleteAuthenticatorsIntent(
                        userId = turnkeyUser.userId, authenticatorIds = listOf(passkey.authenticatorId)
                    )
                )
                refreshTurnKeyUser()
                mutableState.update {
                    it.copy(modifyAuthenticatorsState = UserActionState.Available)
                }
            } catch (t: Throwable) {
                Logger.e(t) { "Error in deleteAuthenticator" }
                mutableState.update {
                    it.copy(
                        modifyAuthenticatorsState = UserActionState.Error(
                            t.message ?: "An error occurred"
                        )
                    )
                }
            }
        }
    }

    fun createAuthenticator() {
        if (mutableState.value.modifyAuthenticatorsState !is UserActionState.AwaitingAction) return

        mutableState.update {
            it.copy(modifyAuthenticatorsState = UserActionState.InProgress)
        }
        screenModelScope.launch {
            val hasher = CryptographyProvider.Default.get(SHA256).hasher()
            val timeHash = hasher.hash(Clock.System.now().epochSeconds.toString().encodeToByteArray()).toHexString()
            val timeHashLast4 = timeHash.takeLast(4)
            val passkeyName = "Bullpen Passkey #$timeHashLast4"
            try {
                val turnkeyUser = state.value.user!!
                val organizationId = state.value.organizationId!!
                // todo: get the user's bullpen username as it could potentially be different from the turnkey username
                turnkeyApi.createAuthenticators(
                    apiKeyStamper, organizationId, CreateAuthenticatorsIntentV2(
                        turnkeyUser.userId, listOf(turnkeyApi.createTurnkeyWebauthnAuthenticator(passkeyName))
                    )
                )
                refreshTurnKeyUser()
                mutableState.update {
                    it.copy(modifyAuthenticatorsState = UserActionState.Available)
                }
            } catch (t: Throwable) {
                Logger.e(t) { "Error in createAuthenticator" }
                mutableState.update {
                    it.copy(
                        modifyAuthenticatorsState = UserActionState.Error(
                            t.message ?: "An error occurred"
                        )
                    )
                }
            }
        }
    }

    fun clearModifyAuthenticatorsStateError() {
        mutableState.update {
            it.copy(modifyAuthenticatorsState = UserActionState.Available)
        }
    }

    fun deleteApiKeys(apiKeys: List<ApiKey>, refreshUser: Boolean): Job? {
        if (mutableState.value.modifyApiKeysState !is UserActionState.AwaitingAction) return null
        if (state.value.user?.userEmail == null) {
            mutableState.update {
                it.copy(modifyApiKeysState = UserActionState.Error("Email must be set to remove api keys."))
            }
            return null
        }

        mutableState.update {
            it.copy(modifyApiKeysState = UserActionState.InProgress)
        }
        return screenModelScope.launch {
            try {
                apiKeys.forEach { apiKey ->
                    val turnkeyUser = state.value.user!!
                    val organizationId = state.value.organizationId!!
                    turnkeyApi.deleteApiKey(
                        apiKeyStamper, organizationId, DeleteApiKeysIntent(
                            userId = turnkeyUser.userId, apiKeyIds = listOf(apiKey.apiKeyId)
                        )
                    )
                }
                if (refreshUser) {
                    refreshTurnKeyUser()
                }
                mutableState.update {
                    it.copy(modifyApiKeysState = UserActionState.Available)
                }
            } catch (t: Throwable) {
                Logger.e(t) { "Error in deleteApiKey" }
                mutableState.update {
                    it.copy(
                        modifyApiKeysState = UserActionState.Error(
                            t.message ?: "An error occurred"
                        )
                    )
                }
            }
        }
    }

    fun createApiKey(apiKey: ApiKey) {
        if (mutableState.value.modifyApiKeysState !is UserActionState.AwaitingAction) return

        mutableState.update {
            it.copy(modifyApiKeysState = UserActionState.InProgress)
        }
        screenModelScope.launch {
            val apiKeyParams = CreateApiKeysIntent.ApiKeyParams(
                apiKey.apiKeyName, apiKey.credential.publicKey, apiKey.expirationSeconds
            )
            try {
                val turnkeyUser = state.value.user!!
                val organizationId = state.value.organizationId!!
                val createApiKeysIntent = CreateApiKeysIntent(turnkeyUser.userId, listOf(apiKeyParams))
                turnkeyApi.createApiKeys(
                    apiKeyStamper, organizationId, createApiKeysIntent
                )
                refreshTurnKeyUser()
                mutableState.update {
                    it.copy(modifyApiKeysState = UserActionState.Available)
                }
            } catch (t: Throwable) {
                Logger.e(t) { "Error in createApiKey" }
                mutableState.update {
                    it.copy(
                        modifyApiKeysState = UserActionState.Error(
                            t.message ?: "An error occurred"
                        )
                    )
                }
            }
        }
    }

    fun clearModifyApiKeysStateError() {
        mutableState.update {
            it.copy(modifyApiKeysState = UserActionState.Available)
        }
    }

    fun setUserEmail(email: String) {
        screenModelScope.launch {
            try {
                val turnkeyUser = state.value.user!!
                val organizationId = state.value.organizationId!!
                TODO()
//                turnkeyApi.updateUser(
//                    webauthnStamper,
//                    organizationId, UpdateUserIntent(
//                        userId = turnkeyUser.userId,
//                        userEmail = email.trim(),
//                        userName = turnkeyUser.userName,
//                        userTagIds = turnkeyUser.userTags
//                    )
//                )
                refreshTurnKeyUser()
            } catch (t: Throwable) {
                Logger.e(t) { "Error in setUserEmail" }
            }
        }
    }

    private fun removeOldSessionApiKeys() {
        val user = state.value.user
        if (user == null) {
            Logger.e { "removeOldSessionApiKeys called when user has not been loaded" }
            return
        }
        val oldSessionApiKeys = user.apiKeys.filter {
            it.apiKeyName == walletManagementSessionApiKeyName
        }.sortedBy {
            it.createdAt.seconds
        }.dropLast(1)
        deleteApiKeys(oldSessionApiKeys, true)
    }

    fun onExit(action: ManagementScreenNavigationState.ExitAction) {
        val user = state.value.user
        if (user == null) {
            Logger.e { "onExit called when user has not been loaded" }
            return
        }
        val allSessionApiKeys = user.apiKeys.filter {
            it.apiKeyName == walletManagementSessionApiKeyName
        }
        screenModelScope.launch {
            // after deleting session api key, refreshing user isn't possible and will throw error
            deleteApiKeys(allSessionApiKeys, false)?.join()
            mutableState.update {
                it.copy(navigationState = action)
            }
        }
    }

}
