package fi.bullpen.kmpapp.screens.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import fi.bullpen.kmpapp.composables.ProgressSpinner
import fi.bullpen.kmpapp.composables.Scaffold
import fi.bullpen.kmpapp.composables.TelegramLoginWidget
import fi.bullpen.kmpapp.data.telegram.TelegramUserAuthToken
import fi.bullpen.kmpapp.screens.home.HomeScreen
import fi.bullpen.kmpapp.screens.utils.getScreenModel
import kotlinx.browser.window
import org.jetbrains.compose.web.dom.*

data object AuthScreen : Screen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow
        val screenModel: AuthScreenModel = getScreenModel()
        val screenState by screenModel.state.collectAsState()
        LaunchedEffect(Unit) {
            val telegramUserAuthToken = getTelegramUserAuthToken()
            if (telegramUserAuthToken != null) {
                screenModel.onTelegramAuth(telegramUserAuthToken)
            }
        }

        if (screenState.authState is AuthState.SignedIn) {
            navigator.replace(HomeScreen)
        }

        Scaffold {
            Div({ classes("mt-48") }) {
                Img(src = "/bullpen-bull-black-bg.png", alt = "Bullpen Logo", attrs = {
                    classes("mx-auto")
                    attr("width", "80")
                    attr("height", "80")
                })
                H2({
                    classes(
                        "mt-4", "text-center", "text-3xl", "favorit", "leading-9", "tracking-tight", "text-zinc-900"
                    )
                }) {
                    if (screenState.authState is AuthState.InProgress) {
                        Text("Connecting to Telegram")
                    } else {
                        Text("Connect to Telegram")
                    }
                }
            }

            Div({ classes("mt-10") }) {
                Form(attrs = {
                    classes("space-y-4", "p-4", "max-w-lg", "mx-auto")
                }) {
                    if (screenState.authState is AuthState.Error) {
                        Div({
                            classes(
                                "bg-red-100",
                                "border",
                                "border-red-400",
                                "text-red-700",
                                "px-4",
                                "py-3",
                                "rounded",
                                "relative"
                            )
                            attr("role", "alert")
                        }) {
                            Div({
                                classes("font-bold")
                            }) {
                                Text("Authentication Error:")
                            }
                            Span({
                                classes("block", "sm:inline")
                            }) {
                                Text((screenState.authState as AuthState.Error).message)
                            }
                        }
                    }
                    when (screenState.authState) {
                        AuthState.SignedOut, is AuthState.Error -> {
                            Div({
                                classes("flex", "justify-center", "items-center", "mt-2", )
                            }) {
                                // todo: use env var for bot name
                                TelegramLoginWidget("BullpenFiBot") { user ->
                                    console.log("On Telegram Auth")
                                    screenModel.onTelegramAuth(user)
                                }
                            }
                        }

                        AuthState.InProgress -> {
                            ProgressSpinner { classes("h-12", "w-12", "mx-auto") }
                        }

                        else -> {}
                    }
                }
            }
        }
    }
}


private fun getTelegramUserAuthToken(): TelegramUserAuthToken? {
    val urlParams = getUrlParams()
    return if (urlParams.contains("auth_date") && urlParams.contains("first_name") && urlParams.contains("hash") && urlParams.contains(
            "id"
        )
    ) {
        TelegramUserAuthToken(
            authDate = urlParams["auth_date"]!!.toULong(),
            firstName = urlParams["first_name"]!!,
            lastName = urlParams["last_name"],
            hash = urlParams["hash"]!!,
            id = urlParams["id"]!!.toULong(),
            photoUrl = urlParams["photo_url"],
            username = urlParams["username"]
        )
    } else {
        null
    }
}

external fun decodeURIComponent(encodedURI: String): String
fun getUrlParams(): MutableMap<String, String> {
    val params = mutableMapOf<String, String>()
    if (window.location.search.isNotEmpty()) {
        val queryString = window.location.search.substring(1)
        val queryParams = queryString.split("&")

        queryParams.forEach { param ->
            val pair = param.split("=")
            params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
        }
    }
    return params
}