package fi.bullpen.kmpapp.screens.turnkeyRecovery

import fi.bullpen.kmpapp.data.bullpen.responses.UsersTurnkeyInfo
import fi.bullpen.kmpapp.screens.generic.UserActionState
import fi.bullpen.kmpapp.service.turnkeyIframeStamper.IframeStamper

data class TurnkeyRecoveryScreenState(
    val recoveryState: UserActionState = UserActionState.Available,
    val iframeStamper: IframeStamper? = null,
    val usersTurnkeyInfo: UsersTurnkeyInfo? = null,
    val successfulRecovery: Boolean = false,
    val defaultPasskeyName: String? = null
)