package fi.bullpen.kmpapp.composables

import androidx.compose.runtime.Composable
import kotlinx.browser.window
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.css.transform
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.svg.Path
import org.jetbrains.compose.web.svg.Svg
import org.jetbrains.compose.web.svg.fill
import org.jetbrains.compose.web.svg.xmlns
import org.w3c.dom.svg.SVGElement

val backToTelegramCallback: () -> Unit = {
    // todo: use env variable
    window.open("https://t.me/BullpenFiBot?start=start", "_self")
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun Scaffold(
    onClickBackToTelegram: () -> Unit = backToTelegramCallback, content: @Composable () -> Unit
) {
    Div({
        classes("w-full", "min-h-dvh", "max-h-dvh", "grid", "grid-cols-10", "overflow-scroll")
    }) {
        Div({
            classes("hidden", "lg:block", "lg:visible", "lg:col-span-4", "2xl:col-span-3", "bg-black", "flex-none", "relative")
        }) {
            Img(src = "/bullpen-logo-white.svg", alt = "Bullpen Logo", attrs = {
                classes("inline-block", "my-12", "mx-8")
                attr("width", "110")
                attr("height", "30")
            })
            Div({
                classes("absolute", "inset-0", "overflow-hidden")
            }) {
                Div({ classes("flex", "justify-end", "items-start", "h-1/3") }) {
                    Img(src = "/wireframe_graphic.svg", alt = "Wireframe Graphic", attrs = {
                        attr("width", "400")
                    })
                }
                Div({ classes("flex", "items-start", "h-1/3") }) {
                    Img(src = "/shield.svg", alt = "Shield", attrs = {
                        classes("ml-20")
                        attr("width", "260")
                        attr("height", "260")
                    })
                }
                Div({ classes("flex", "items-end", "h-1/3") }) {
                    Img(src = "/wireframe_graphic.svg", alt = "Wireframe Graphic", attrs = {
                        attr("width", "400")
                        style {
                            transform {
                                scaleX(-1)
                                scaleY(-1)
                            }
                        }
                    })
                }
                Div({ classes("gradient-green-top") })
                Div({ classes("gradient-green-bottom") })
            }
        }

        Div({
            classes("col-span-10", "lg:col-span-6", "2xl:col-span-7", "mb-6")
        }) {
            Nav {
                Div({
                    classes("p-3", "border-gray-200", "dark:bg-gray-900", "dark:border-gray-700", "shadow")
                }) {
                    Button({
                        classes("inline-flex", "p-2", "hover:bg-green-100", "rounded-lg")
                        onClick {
                            onClickBackToTelegram()
                        }
                    }) {
                        ChevronLeft {}
                        Div {
                            // todo: use env variable
                            Text("BullpenFiBot")
                        }
                    }
                }
            }
            content()
        }
    }
}

@OptIn(ExperimentalComposeWebSvgApi::class)
@Composable
private fun ChevronLeft(attrs: AttrBuilderContext<SVGElement>? = null) {
    Svg(viewBox = "0 0 24 24", attrs = {
        classes("w-6", "h-6")
        xmlns("http://www.w3.org/2000/svg")
        fill("none")
        attr("stroke-width", "1.5")
        attr("stroke", "currentColor")
        attrs?.invoke(this)
    }) {
        Path(d = "M15.75 19.5 8.25 12l7.5-7.5", attrs = {
            attr("stroke-linecap", "round")
            attr("stroke-linejoin", "round")
        })
    }
}
